
const fancybox = require('@fancyapps/fancybox');
const fancyboxCSS = require('@fancyapps/fancybox/dist/jquery.fancybox.css');

const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

/************************************************************************************************/
//
// ! Eigentliche Skripte
//


var rwd_menu = 0;
var cachedWidth = jQuery(window).width();
var grid = 0;

const resizetrigger = function() {

    if (jQuery("#rwd_menu_link").css("display") === "block") {
        if (rwd_menu === 0) {
            rwd_menu = $.jPanelMenu({ menu: '#mobile_menu', trigger: '#rwd_menu_link', direction: 'left' });
        }
        if (jQuery("#jPanelMenu-menu").length === 0) {
            rwd_menu.on();
        }
    } else {
        if (rwd_menu) {
            rwd_menu.off();
        }
    }

}

/************************************************************************************************/
//
// ! Scroll-Funktionen
//


function scrollToTop() {

    jQuery(window).scrollTop(jQuery(window).scrollTop() / 1.6);
    if (jQuery(window).scrollTop() > 5) {
        window.setTimeout(scrollToTop, 100);
    } else {
        jQuery(window).scrollTop(0);
    }
}

/************************************************************************************************/
//
// ! Ready
//


jQuery(document).ready(function() {

    jQuery("table").wrap('<div class="table" />');

    resizetrigger();

    jQuery(window).resize(function() {
        var newWidth = jQuery(window).width();
        if (newWidth !== cachedWidth) {
            resizetrigger();
            cachedWidth = jQuery(window).width();
        }
    });

    jQuery("#top a").click(function(e) {
        e.preventDefault();
        scrollToTop();
    });

    jQuery('div.top a').click(function(e) {
        e.preventDefault();
        jQuery('html, body').animate({
            scrollTop: 0
        }, 300);
    });

    imagesLoaded(".grid", function() {
        var width = Math.floor(jQuery(".grid").find('.grid-sizer').length?jQuery(".grid").find('.grid-sizer')[0].clientWidth:jQuery(".grid-sizer-grid").find('.grid-sizer')[0].clientWidth)-1;
        jQuery('.grid-item').each(function() {
            jQuery(this).width(width);
        });
        for(i=0;i<24;i++) {
            jQuery(".grid-item.picture").eq(i).find(".sort").text("a"+i);
            jQuery(".grid-item.blog.home").eq(i).find(".sort").text("a"+i);
            jQuery(".grid-item.page").eq(i).find(".sort").text("a"+i);
        };
        grid = jQuery('.grid').isotope({
            // options
            itemSelector: '.grid-item',
            getSortData: {
                'name': '.sort',
            },
            sortBy: 'name',
            masonry: {
                gutter: 16,
                columnWidth: width,
            },
        }).on('layoutComplete',function() {
            jQuery('.grid').addClass('show');
        }).on('arrangeComplete',function() {
        });
        grid.isotope();
    })

    new Swiper(".slideshow",{
        slidesPerView: 3.1,
    });

    jQuery(".imageportfolio .swiper-container .swiper-slide").each(function() {
        jQuery(this).width(jQuery(this).find("img").width());
    });
        
    new Swiper(".imageportfolio .swiper-container",{
        slidesPerView: 'auto',
        spaceBetween: 30,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        grabCursor: true,
        simulateTouch: true,
        freeMode: {
            enabled: supportsTouch,
            sticky: false
        },
        navigation: {
            prevEl: '.portfolio-prev',
            nextEl: '.portfolio-next',
        }
    });

    jQuery('.taxonomies .taxonomy').on('click',function() {
        if(jQuery(this).data('series')!='all') {
            grid.isotope({ filter: '.'+jQuery(this).data('series') });
        } else {
            grid.isotope({ filter: '*' });
        }
        jQuery('.taxonomies .taxonomy').removeClass('active');
        jQuery(this).addClass('active');
    })

    new Swiper(".taxonomy.swiper-container",{
        slidesPerView: 2.4,
        spaceBetween: 16,
        grabCursor: true,
        simulateTouch: true,
        slidesOffsetBefore: 16,
        freeMode: {
            enabled: supportsTouch,
            sticky: false
        },
        breakpoints: {
            0: {
                slidesPerView: 2.4,
            },
            768: {
                slidesPerView: 'auto',
            }
        }
    });

    jQuery('.rwd').on('click',function() {
        jQuery(this).toggleClass('closed');
        jQuery('.nav').toggleClass('closed');
    });

    let url = window.location.href;
    var soulstrip = 'ff-'+url.split('/').pop();
    document.querySelectorAll('#soulstrips a').forEach(function (el) {
        if (el.id == soulstrip) {
            el.click();
        }
    });

    GreenAudioPlayer.init({
        selector: '.audio',
        stopOthersOnPlay: true
    });



});